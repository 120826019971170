
import LimitDialogTemplate from '@/modules/membership/LimitDialogTemplate.vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import { defineComponent, ref } from 'vue'
import Pic from '@/components/Pic.vue'
import List from '@/components/AsyncNullableList.vue'
import Holder from '@/provider/Holder.vue'
import TheRankListItem from '@/modules/membership/tournament/components/TheRankListItem.vue'
import {
  RankListSchema,
  TournamentSchema,
  readRankList,
  tournamentDetail,
  isJoin,
} from '@/modules/membership/tournament/tournament.api'
import { cd } from 'essential/tools/format'
import { openDialog } from '@/components/popup/popup'
import ParticipateConfirmDialog
  from '@/modules/membership/tournament/components/ParticipateConfirmDialog.vue'
import Time from '@/components/Time.vue'

export default defineComponent({
  name: 'TournamentDetail',
  components: { Time, Pic, TheRankListItem, Holder, List, PageWithHeader },
  props: {
    tournamentId: String,
  },
  setup (props) {
    const days = ref(0)
    const hours = ref(0)
    const minutes = ref(0)
    const seconds = ref(0)

    const rankList = ref<Array<RankListSchema> | null>(null)
    const detail = ref<TournamentSchema>({} as TournamentSchema)

    const joinStatus = ref(0)

    const requestData = () => {
      isJoin({ tournamentId: props.tournamentId }).then(resp => joinStatus.value = resp as number)

      readRankList({ tournamentId: props.tournamentId })
        .then(resp => rankList.value = resp as Array<RankListSchema>)

      tournamentDetail({ tournamentId: props.tournamentId })
        .then(resp => {
          detail.value = resp as TournamentSchema
          console.log(new Date(detail.value.endTime), new Date(detail.value.startTime))
          const countTime = detail.value.status === 1 && detail.value.endTime > new Date().getTime()
            ? detail.value.endTime - new Date().getTime()
            : detail.value.endTime - detail.value.startTime
          let flag = true
          if (detail.value.status !== 2) {
            // 进行中
            cd(countTime, (day, hour, minute, second) => {
              if (flag) {
                days.value = day
                hours.value = hour
                minutes.value = minute
                seconds.value = second
                if (detail.value.status === 0) {
                  flag = false
                }
              }
            })
          }
        })
    }

    requestData()

    // const level = computed(() => {
    //   return state.account.levelModel.level
    // })

    const participate = () => {
      if (detail.value.isJoin === 1) {
        openDialog(ParticipateConfirmDialog, { info: detail.value, onConfirm: requestData })
      } else {
        openDialog(LimitDialogTemplate, {
          i18nPath: 'tournamentdetail_15',
          i18nArgs: {
            level: detail.value.levelValue,
          },
        })
      }
    }

    const zeroTime = (n: number | string, c?: number) => ('000000' + n).slice(-(c || 2))

    return {
      rankList,
      detail,
      days,
      hours,
      minutes,
      seconds,
      participate,
      joinStatus,
      zeroTime,
    }
  },
})
