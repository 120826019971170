<template>
  <DialogTemplate no-title>
    <div class="py-32 t-center" style="line-height: 24px;">
      <Icon name="check" class="bg-success mb-20" style="font-size: 40px;color: white;border-radius: 20px" />
      <t as="p" class="px-16" path="tournamentdetail_12" :args="{tournament: `${info.name}`, points: `${info.entryFee}`}">
        Are you sure to participate in the {{ info.name }}? You need to pay {{ info.entryFee }} points.
      </t>
    </div>
    <template #confirm>
      <Button class="flex-1 primary" @click="submit" :progress="progress">
        <t path="tournamentdetail_14" #="{td}" custom>{{ td || 'OK' }}</t>
      </Button>
    </template>
  </DialogTemplate>
</template>

<script lang="ts">
import DialogTemplate from '@/views/DialogTemplate.vue'
import { defineComponent } from 'vue'
import Button from '@/components/Button.vue'
import { tournamentJoin } from '@/modules/membership/tournament/tournament.api'
import useRequest from '@/hooks/useRequest'
import Icon from '@/components/Icon.vue'

export default defineComponent({
  name: 'ParticipateConfirmDialog',
  components: { Icon, DialogTemplate, Button },
  props: {
    info: {
      type: Object,
      required: true,
    },
  },
  setup (props, ctx) {
    const [commit, progress] = useRequest(tournamentJoin)

    return {
      submit () {
        commit({
          tournamentId: props.info.tournamentId,
        }).then(() => {
          ctx.emit('close')
          ctx.emit('confirm')
        })
      },
      progress,
    }
  },
})
</script>
