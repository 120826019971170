<template>
  <div class="df-between df-middle list f-md" :class="{'participate': participate}">
    <div style="width: 24px;">
      <template v-if="list.rankNum > 0 && list.rankNum <= 20">{{ list.rankNum }}</template>
      <template v-else>- -</template>
    </div>
    <div class="f-md df-middle flex-2">
      <div class="mr-6 list-avatar">
        <Pic :src="list.avatarPic" fallback="/img/user/Avatar@2x.png" width="32" height="32" class="avatar" />
        <Pic v-if="list.rankNum === 1" src="membership/crown" class="crown" width="16" height="16" />
      </div>
      <p class="nickname">{{ list.nickName }}</p>
    </div>
    <div class="flex-1 t-center">{{ list.profitRate || list.profitRate === 0 ? `${profitRate}%` : '- -' }}</div>
    <div class="flex-1 f-bold t-right">
      <template v-if="list.rankNum > 0 && list.rankNum <= 20">
        <span class="mr-4">{{ list.prize || '- -' }}</span>
        <t path="membership_13">pts</t>
      </template>
      <template v-else>- -</template>
    </div>
  </div>
  <Line />
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import Line from '@/components/Line.vue'
import Pic from '@/components/Pic.vue'
import { mul } from 'essential/tools/math'

export default defineComponent({
  name: 'TheRankListItem',
  components: { Pic, Line },
  props: {
    list: {
      type: Object,
      required: true,
    },
    participate: Boolean,
  },
  setup (props) {
    const profitRate = computed(() => mul(props.list.profitRate, 100))
    return {
      profitRate,
    }
  },
})
</script>

<style scoped lang="scss">
  .list {
    height: 48px;
  }

  .participate:first-child {
    background: var(--color-asst-light);
    // margin: 0 -16px 4px;
    margin: 0 -16px;
    padding: 0 16px;
  }

  .list-avatar {
    position: relative;
    .avatar {
      border-radius: 50%;
    }
  }

  .crown {
    position: absolute;
    right: 0;
    top: -8px;
  }

  .nickname {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word;
  }

</style>
