<template>
<PageWithHeader>
  <template #title>
    {{detail.name || 'detail'}}
  </template>
  <div class="px-16">
    <div class="mt-8 mb-24 d-f">
      <Pic :src="detail.pic" class="mr-24" width="88" height="88" />
      <div class="flex-1">
        <div class="d-f f-sm mb-16">
          <div class="mr-20">
            <p class="f-400 mb-4 f-md">
              <span class="mr-4">{{detail.endPrize}}</span>
              <t path="membership_13">pts</t>
            </p>
            <t as="p" path="tournamentdetail_2" class="c-title f-sm">Prize pool</t>
          </div>
          <div class="mr-20">
            <p class="f-400 mb-4 f-md">
              <span class="mr-4">{{detail.entryFee}}</span>
              <t path="membership_13">pts</t>
            </p>
            <t as="p" path="tournamentdetail_3" class="c-title f-sm">Entry fee</t>
          </div>
          <div>
            <p class="f-400 mb-4 f-md">{{detail.participant}}</p>
            <t as="p" path="tournamentdetail_4" class="c-title f-sm">Participants</t>
          </div>
        </div>
        <div class="d-f f-sm mb-16">
          <div class="mr-20">
            <Time tag="p" class="f-400 mb-4 f-md" style="white-space:nowrap;"
              :format="'MM-dd HH:mm'" :time="detail.startTime" />
            <t as="p" path="tournamentdetail_5" class="c-title f-sm">Kick-off</t>
          </div>
          <div class="">
            <p class="f-400 mb-4 f-md">
              <span>{{detail.duration}}</span>
              <!--                <t path="tournamentdetail_7">days</t>-->
            </p>
            <t as="p" path="tournamentdetail_6" class="c-title f-sm">Duration</t>
          </div>
        </div>
        <div v-if="detail.status !== 2 && detail.endTime > new Date().getTime()"
          class="df-middle t-center c-theme f-sm">
          <template v-if="days > 0">
            <div class="time">{{days}}
              <t path="tournamentdetail_7">days</t>
            </div>
            <div class="time-divider"></div>
          </template>
          <div class="time">{{zeroTime(hours)}}</div>
          <div class="time-divider">:</div>
          <div class="time">{{zeroTime(minutes)}}</div>
          <div class="time-divider">:</div>
          <div class="time">{{zeroTime(seconds)}}</div>
        </div>
      </div>
    </div>
    <p class="f-md f-400" style="line-height: 18px;">
      {{detail.tips}}
    </p>
    <button v-if="detail.status === 2 || detail.endTime < new Date().getTime()"
      class="mt-24 btn f-lg f-bold" style="width: 100%;">
      <t path="tournamentdetail_19" #="{td}" custom>{{td || 'Closed'}}</t>
    </button>
    <button v-else-if="joinStatus === 1" class="mt-24 btn f-lg f-bold" style="width: 100%;">
      <t path="tournamentdetail_18" #="{td}" custom>{{td || 'Participated'}}</t>
    </button>
    <button v-else class="mt-24 btn f-lg f-bold br-lg"
      :class="{primary: detail.status === 1}" style="width: 100%;"
      @click="detail.status === 1 && participate()">
      <t path="tournamentdetail_8" #="{td}" custom>{{td || 'Participate'}}</t>
    </button>
    <div class="py-32">
      <!--        <t as="p" path="" custom class="f-lg f-bold board-title">Leaderboard</t>-->
      <p class="f-nm f-bold board-title">Leaderboard</p>
      <div class="c-title f-sm df-middle df-between pl-24 title">
        <t as="p" path="tournamentdetail_9" class="flex-2">Memeber</t>
        <t as="p" path="tournamentdetail_10" class="flex-1 t-center">Profit%</t>
        <t as="p" path="tournamentdetail_11" class="flex-1 t-right">Prize</t>
      </div>
      <Holder :content="rankList">
        <List :list-data="rankList" #="{ item }">
          <TheRankListItem :list="item" :participate="joinStatus === 1" />
        </List>
      </Holder>
    </div>
  </div>
</PageWithHeader>
</template>

<script lang="ts">
import LimitDialogTemplate from '@/modules/membership/LimitDialogTemplate.vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import { defineComponent, ref } from 'vue'
import Pic from '@/components/Pic.vue'
import List from '@/components/AsyncNullableList.vue'
import Holder from '@/provider/Holder.vue'
import TheRankListItem from '@/modules/membership/tournament/components/TheRankListItem.vue'
import {
  RankListSchema,
  TournamentSchema,
  readRankList,
  tournamentDetail,
  isJoin,
} from '@/modules/membership/tournament/tournament.api'
import { cd } from 'essential/tools/format'
import { openDialog } from '@/components/popup/popup'
import ParticipateConfirmDialog
  from '@/modules/membership/tournament/components/ParticipateConfirmDialog.vue'
import Time from '@/components/Time.vue'

export default defineComponent({
  name: 'TournamentDetail',
  components: { Time, Pic, TheRankListItem, Holder, List, PageWithHeader },
  props: {
    tournamentId: String,
  },
  setup (props) {
    const days = ref(0)
    const hours = ref(0)
    const minutes = ref(0)
    const seconds = ref(0)

    const rankList = ref<Array<RankListSchema> | null>(null)
    const detail = ref<TournamentSchema>({} as TournamentSchema)

    const joinStatus = ref(0)

    const requestData = () => {
      isJoin({ tournamentId: props.tournamentId }).then(resp => joinStatus.value = resp as number)

      readRankList({ tournamentId: props.tournamentId })
        .then(resp => rankList.value = resp as Array<RankListSchema>)

      tournamentDetail({ tournamentId: props.tournamentId })
        .then(resp => {
          detail.value = resp as TournamentSchema
          console.log(new Date(detail.value.endTime), new Date(detail.value.startTime))
          const countTime = detail.value.status === 1 && detail.value.endTime > new Date().getTime()
            ? detail.value.endTime - new Date().getTime()
            : detail.value.endTime - detail.value.startTime
          let flag = true
          if (detail.value.status !== 2) {
            // 进行中
            cd(countTime, (day, hour, minute, second) => {
              if (flag) {
                days.value = day
                hours.value = hour
                minutes.value = minute
                seconds.value = second
                if (detail.value.status === 0) {
                  flag = false
                }
              }
            })
          }
        })
    }

    requestData()

    // const level = computed(() => {
    //   return state.account.levelModel.level
    // })

    const participate = () => {
      if (detail.value.isJoin === 1) {
        openDialog(ParticipateConfirmDialog, { info: detail.value, onConfirm: requestData })
      } else {
        openDialog(LimitDialogTemplate, {
          i18nPath: 'tournamentdetail_15',
          i18nArgs: {
            level: detail.value.levelValue,
          },
        })
      }
    }

    const zeroTime = (n: number | string, c?: number) => ('000000' + n).slice(-(c || 2))

    return {
      rankList,
      detail,
      days,
      hours,
      minutes,
      seconds,
      participate,
      joinStatus,
      zeroTime,
    }
  },
})
</script>
<style scoped lang="scss">
.title {
  height: 40px;
  border-bottom: 1px solid rgba(161, 174, 182, 0.5);
}

.time {
  border-radius: 2px;
  background: var(--color-text);
  padding: 0 2px;
  min-width: 18px;
  height: 18px;
  line-height: 18px;
}

.time-divider {
  width: 6px;
  color: var(--color-text);
  font-weight: bold;
}

.board-title {
  line-height: 28px;
}
</style>
